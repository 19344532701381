import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './Homepage';
import GeoMarkers from './geoMarkers';
import Notes from './Notes';
import Pictures from './Pictures';
 
function App() {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/geomarkers' element={<GeoMarkers />} />
                <Route path='/notes' element={<Notes /> } />
                <Route path='/pictures' element={<Pictures /> } />
            </Routes>
        </Router>
    );
}
 
export default App;