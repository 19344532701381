import './Pictures.css'
import React, { useEffect, useState } from 'react';
 
function ImageGallery() {
    const [imageKeys, setImageKeys] = useState([]);
  
    useEffect(() => {
        async function fetchImageKeys() {
            try {
            const response = await fetch('https://r2-image-worker.hunch-harvest-0t.workers.dev/list', {
                method: 'GET',
                mode: 'cors',              // Explicitly use CORS
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer a0561fd649cdb6baa784055f051bad796ea0afef17fca38219549deeba4e8c1a',
                },
            })
    
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
    
            const data = await response.json();
            setImageKeys(data.keys ?? []);
            } catch (error) {
            console.error('Error fetching image keys:', error);
            }
        }
  
      fetchImageKeys();
    }, []);

    return (
        <div className="page-content">
            <h1>My pictures</h1>
            {/* <p>Click on any image to get the full resolution</p> */}
            <style>{'body { background-color: rgb(5, 0, 40); color: #c6c6c6;}'}</style>
            {/* retrieve objects from cloudflare R2 storage */}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {imageKeys.map((key) => (
                    <div key={key} style={{ margin: '10px' }}>
                    <img
                        src={`https://r2-image-worker.hunch-harvest-0t.workers.dev/${key}`} 
                        alt={key}
                        style={{ maxWidth: '900px', maxHeight: '900px', objectFit: 'cover' }}
                    />
                    {/* <p>{key}</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ImageGallery;